@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300&display=swap");

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  height: 100vh;
}
body::-webkit-scrollbar {
  width: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.messageBtn {
  text-decoration: none;
  font-size: 30px;
  text-align: left;
}
@font-face {
  font-family: "Logo";
  src: url("./TheHistoriaDemo.otf");
  font-style: normal;
}

@font-face {
  font-family: "News";
  src: url("./News.otf");
  font-style: normal;
}

.App {
  background-color: #fff;
}

.rhap_container {
  background-color: transparent !important;
  box-shadow: none !important;
}

.header-outter {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 369;
  width: 90%;
  height: 79px;
  margin-left: 4.9%;
  bottom: 35px;
  border-radius: 26px;
  background: #fff;
  box-shadow: 10px 10px 20px #afafafa9, -10px -10px 20px #ffffff80;
}

.buttons-outter {
  width: 90%;
  height: 90%;
  display: grid;
  justify-content: space-evenly;
  align-items: center;
  grid-template-columns: auto auto auto auto;
}
.button-outter {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f3f3f346;
}
.button-outter-logout {
  text-decoration: none;
  color: #000;
  cursor: pointer;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #00fc0021;
}

.logOutBtnImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.button-outter:hover {
  background-color: #000000bb;
  color: #fff;
}
.active {
  border: 2px solid #000;
}
.online {
  border: 2px solid rgb(6, 168, 6);
}
.float-right {
  position: absolute;
  top: 16px;
  right: 30px;
}
.navbar-outter {
  position: fixed;
  z-index: 69;
  margin: 0;
  top: 0;
  border-top: 1.5px solid #0000008c;
  border-bottom: 1.5px solid #0000008c;
  padding: 10px 0;
  width: 99.9%;
  text-align: center;
  background-color: #fff;
}
.outBox {
  width: auto;
  line-height: 35px;
}

.sauravDutt-projects {
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 210px;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: auto;
  padding-top: 10px;
  justify-content: space-around;
  position: absolute;
}
.default {
  background-color: #ffffff;
  width: 380px;
  height: 170px;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 15px;
  box-shadow: 5px 5px 15px #1c1c1c1a;
  z-index: 10;
}
.default h1 {
  margin: 0;
  margin-top: 15px;
  margin-left: 10px;
}
.last {
  background-color: transparent;
  display: grid;
  grid-template-columns: auto;
  box-shadow: none;

}
.last div {
  text-align: center;
  height: 80px;
}
.brand-name {
  user-select: none;
  font-family: "News";
  font-weight: 300;
  font-size: 50px;
  margin: 0;
  padding: 0;
  margin-top: 6px;
}
.lower {
  margin: 0;
  margin-bottom: -5px;
  padding: 0;
  font-family: "Logo";
  cursor: pointer;
  font-size: 20px;
  font-weight: 100;
  color: #00000083;
}
.lower span {
  margin-left: 10px;
}
.secondFont {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 9px;
  font-weight: 100;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
}
.mainAreaTwo {
  border: 2px solid #000;
  border-radius: 10px;
  height: 77%;
  margin: 0;
  margin-top: 13px;
}
.mainArea-outter {
  display: grid;
  margin-top: 96px;
  grid-template-columns: 35% 60%;
}
.mainArea {
  position: relative;
  width: 390px;
  height: 85vh;
  margin: 0 auto;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* These classnames are title prop values  (-- Start --)*/
.articleBoiler {
  padding: 5px;
  z-index: 68;
  padding-bottom: 10px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 350px;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #000000e7;
}
.articleBoiler h1 {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  padding: 0;
}
.articleBoiler h4 {
  margin: 0;
  float: right;
  margin-right: 5px;
  margin-top: -5px;
}
.articleBoiler p {
  width: 90%;
  margin: 0 auto;
  margin-top: 25px;
  text-align: justify;
}
.bigQuots {
  font-size: 20px;
  font-family: "News";
}
.imgBoiler {
  width: 100%;
  height: 270px;
  border-bottom: 1px solid #000000d8;
  background-color: #fff;
}

.userTag {
  width: 150px;
  height: 50px;
  display: grid;
  cursor: pointer;
  border-radius: 20px;
  margin: 20px 15px;
  background-color: #000000c9;
  color: #fff;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.userTag:hover {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
}
.userTag img {
  height: 30px;
  border-radius: 50px;
}
.userTag p {
  margin-top: -5px;
  width: 100%;
  font-weight: 600;
}

.imgBoiler img {
  width: 100%;
}

.bannerBoiler {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  height: 370px;
}
.bannerBoilerBig {
  background-color: #fff;
  width: 100%;
  height: 300px;
}
.narrow {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 600px;
  height: 100px;
}

.bannerBoiler div {
  height: 100%;
}
.bannerBoiler div h1 {
  font-family: "Logo";
  font-weight: 300;
  line-height: 32px;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 40px;
  margin-left: 5px;
}
.bannerBoiler div p {
  width: 90%;
  margin: 0 auto;
  margin-top: -5px;
}
.positionRight {
  width: 44%;
  float: right;
}
.positionCenter {
  height: 257px;
}
.banner-realpoint {
  border: 3px solid #0000009d;
  width: 95%;
  height: 350px;
  margin-top: -35px;
}
.mapHeding {
  margin: 0;
  margin-left: -100px;
  display: flex;
  height: 100px;
  border-radius: 10px;
  align-items: center;

  justify-content: center;
  line-height: 31px;
  background-color: #fff;
  box-shadow: 5px 5px 5px #d0d0d0b6, -5px -5px 5px rgba(240, 240, 240, 0.651);
  width: 160px;
  text-align: center;
  overflow: hidden;
  padding: 0;
  color: rgba(0, 0, 0, 0.822);
  font-weight: 100;
  font-size: 40px;
  z-index: 69;
  font-family: "Logo";
  transform: rotate(-5deg);
}

.centerPara {
  margin: 0;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.second {
  color: rgb(10, 163, 36);
}
.positionLeft {
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 69%;
  margin-top: 15px;
}
.positionLeftOne {
  margin-left: 100px;
}
.login-outter {
  margin-top: 200px;
}
.btsOutter-boxs {
  width: 100%;
  margin-top: 100px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.btsOutter {
  box-shadow: 5px 5px 15px #1c1c1c25;
  border-radius: 5px;
  height: 300px;
  width: 340px;
  background-color: #00000000;
  background-image: url("./img/mainFolioPicture.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.bts-content-div {
  position: absolute;
  width: 320px;
  margin: 0 auto;
  margin-top: 228px;
  margin-left: 15px;
}
.floatRight {
  float: right;
  margin-right: 5px;
}
.bts-products-div {
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
  display: grid;
  grid-template-columns: auto;
  overflow: hidden;
  overflow-x: scroll;
}
.bts-content-div h2 {
  font-weight: 300;
  padding: 0;
  margin: 5px;

  margin-top: 15px;
  font-family: 'logo';
  letter-spacing: 2px;
}
.bts-content-div p {
  float: right;
  font-size: 12px;
  margin-top: -20px;
  margin-right: 5px;
}

.bts-card {
  margin: 4px;
}
.bts-card-one {
  margin: 5px;
z-index: 699;
  margin-top: 400px;
  margin-bottom: 140px;
  padding: 10px 1px;
  border-radius: 10px;
}
.bts-card-one p {
  margin-top: 35px;
  margin-left: 20px;
}
.smallText {
  font-size: 14px;
  color: rgb(13, 114, 13);
}
.bts-btn-outter {
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: space-evenly;
}
.bts-btn-outter div {
  background-color: #f9f9f911;
  background-image: url('./img/projects.png');
  box-shadow: 5px 5px 15px #1d1d1d25;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 70px;
  border-radius: 5px;
  cursor: pointer;
}
.bts-btn-outter div:hover {
  background-color: #000;
  background-image: url('./img/projectsWhite.png');
}
.bts-card-one img {
  width: 290px;
}
.loginContainer {
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff4f;
  text-align: center;
  padding: 10px;
  margin-bottom: 90px;
}
.loginContainer img {
  width: 370px;
  margin-top: -90px;
  margin-bottom: -50px;
}
.loginContainer p {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.dashBoard-outter {
  margin-top: 120px;
  display: grid;
  grid-template-columns: auto;
}
.userLogout {
  box-shadow: 5px 5px 15px #00000038;
  position: fixed;
  width: 90%;
  margin-left: 4%;
  display: grid;
  border-radius: 15px;
  padding: 5px;
  z-index: 69;
  background-color: #fafafa;
  grid-template-columns: 35% 65%;
}

.userLogout img {
  border-radius: 50%;
  width: 69px;
}

.userDetails {
  margin: 0;
  margin-left: -12%;
  padding-top: 10px;
  padding-left: 15px;
}
.userDetails h2 {
  margin: 0;
  padding: 0;
}
.userDetails p {
  margin: 0;
  padding: 0;
}
.userDetails button {
  margin-top: 10px;
  background-color: transparent;
  border: none;
  font-family: "Logo";
  font-weight: 100;
  font-size: 30px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: #000;
  user-select: none;
}
.onlineDot {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 20px;
}
.userStats {
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 160px;
  margin-top: 170px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.userStats p {
  width: 60%;
  text-align: justify;
  margin-left: -20px;
  line-height: 25px;
}
.userStats p span {
  font-family: "Logo";
  font-size: 25px;
  font-weight: 300;
}
.userStats p img {
  width: 104%;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}
.userImageDashboard-outter {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backgroundImgDashboard {
  width: 100%;
}

.news-outter {
  margin-top: 100px;
}
.news-outter h1 {
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 3px dotted #0000002f;
  color: #00000088;
  padding-left: 20px;
  font-weight: 300;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: fixed;
  width: 100%;
}
.play-outter {
  width: 350px;
  margin: 0 auto;
  background-color: transparent;
}
.song-outter {
  display: grid;
  width: 355px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 11px;
  padding-bottom: 10px;
  grid-template-columns: auto;

  box-shadow: 5px 5px 10px #d0d0d0, -5px -5px 10px #f0f0f0;
}

.song-title {
  text-align: center;
  margin-top: -35px;
}
.song-title h2 {
  font-weight: 300;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.song-outter-outter {
  display: grid;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 10px;
  position: fixed;
  top: 89px;
  z-index: 200;
  grid-template-columns: auto;
  border-bottom: 1.5px solid #0000008c;
}
.podcast-banner {
  width: 370px;
  height: 370px;
  background-image: url("./img/golfOne.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.podcast-banner-div {
  padding: 5px;
  background-color: #ffffffb9;
  margin: 0;
  margin-top: 25px;
}
.podcast-banner-div h1 {
  font-family: "Logo";
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-size: 40px;
}
.podcast-banner div p {
  text-align: center;
  margin-top: -15px;
}
.utilities-info {
  width: 90%;
  margin: 0 auto;
  text-align: justify;
  margin-bottom: 15px;
}
.phoneNumber {
  text-decoration: none;
  color: #000;
}
.buttons-banner-outter {
  width: 300px;
  margin: 0 auto;
  height: 40px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  padding: 0;
  padding-bottom: 25px;
}
.buttons-banner-outter div {
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 5px;
}
.buttons-banner-outter div p {
  margin-top: 10px;
  margin-right: 6px;
}
.comingSoonUtilities {
  margin-top: -135px;
}
.podcast-outter {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
}

.sceneOne {
  background-image: url("./img/podcast.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 360px;
  height: 280px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
}
.comments-outter {
  background-color: #5252521e;
  border-radius: 10px;
  width: 300px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 10px;
  margin-bottom: 135px;
}
.comments-outter p {
  text-align: center;
}
.comments-outter div {
  margin-right: 20px;
  margin-bottom: 10px;
}
.margin-top-imp {
  margin-top: 115px;
}
.timeInaBottle {
  height: 75px;
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  width: 90%;
  background-color: #252525;
  display: grid;
  grid-template-columns: 20% 80%;
  justify-content: center;
  box-shadow: 5px 5px 18px #d3d3d3da, -5px -5px 18px #ededed52;
}
.timeInaBottle div {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
}

.songImg {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  background-image: url("./img/podcast.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 25px;
}
.news-outter h1 sup {
  font-family: "Logo";
  font-weight: 100;
  font-size: 35px;
}
.news-outter h1 span {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 100;
  font-size: 16px;
}
.chatBot-outter {
  margin: 120px auto;
  margin-bottom: 130px;
  width: 500px;
  padding: 0;
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
}
.chatBot-outter div {
  border-radius: 10px;
  display: grid;
}
.head-gtown {
  height: 280px;
  grid-template-columns: 45% 55%;
  gap: 25px;
  padding: 0px;
  margin: 0px;
}
.img-head-gtown img {
  margin: 0;
  margin-left: 3px;
  margin-top: -10px;
  padding: 0;
  width: 210px;
}
.info-head-gtown {
  padding: 5px;
  text-align: center;
  height: 135px;
}
.info-head-gtown h1 {
  font-family: "Logo";
  font-weight: 100;
  color: #000;
}
.secondFont-head {
  font-family: "Sergio ui";
  font-weight: 300;
  color: #0000004b;
  font-size: 20px;
  margin-top: -42px;
}
.heidBtn {
  display: none;
}
.showBtn {
  margin-top: 10px;
  margin-left: 10px;
  padding: 5px;
  display: block;
  cursor: pointer;
}
.memberID-btn {
  height: 40px;
  display: grid;
  grid-template-columns: 30% 70%;
}
.memberID-btn input {
  border: none;
  border-bottom: 2px solid #000;
  padding: 0;
  padding-left: 10px;
  margin: 0;
  font-size: 20px;
  background-color: transparent;
}
.treasury-btn {
  color: #000;
  width: 130px;
  border: 2px solid #000;
  padding: 5px 0px 5px 10px;
  font-size: 20px;
  grid-template-columns: 30% 70%;
  gap: 5px;
  font-weight: 400;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  height: 55px !important;
  line-height: 17px;
}
.treasury-btn:hover {
  border: 1px solid #000;
}
.treasury-btn p {
  margin: 0;
  padding: 0;
}

.info-gtown-map {
  border: 2px solid #000;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto;
}
.info-gtown-map div {
  height: 320px;
  width: 369px;
}
.info-gtown-map div h2 {
  font-weight: 300;
  font-size: 25px;
  margin-left: 20px;
}
.info-gtown-map button {
  margin-left: 20px;
}
.social-btns {
  color: #000000;
  margin: 0;
  text-decoration: none;
}
.realpoint-branding-outter {
  margin: 0 auto;
  margin-top: -10px;
  height: 60px;
  width: 50%;
  line-height: -5px;
  font-size: 20px;
  line-height: 18px;
  font-weight: 300;
  display: grid;
  padding-top: 13px;
  text-align: left;
}
.gtown-btn {
  cursor: pointer;
}
.info-outter {
  background-color: #fff;
  z-index: 201;
  position: absolute;
  width: 100%;
  display: grid;
  margin-top: -20px;
  grid-template-columns: auto;
  align-items: center;
  justify-content: center;
}
.infoOneDiv {
  margin-bottom: 150px;
}
.infoOneDiv h1 {
  font-size: 40px;
  margin: 0;
  font-weight: 300;
  margin-left: 30px;
  font-family: "logo";
}
.infoOneDiv p {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: justify;
}
.x-x {
  font-weight: 300;
  font-size: 30px;
  font-family: "Logo";
  text-align: center;
}

.showcase-box {
  height: 320px;
  margin-top: 150px;
  border-radius: 50px;
  box-shadow: 15px 15px 49px #b8b8b885, -15px -15px 49px #ffffff;
  overflow: hidden;
}
.showcase-box img {
  margin-top: 45px;
  margin-left: 42px;
}
.showcase-box p {
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
}
.showcase-box h1 {
  font-family: "logo";
  font-weight: 600;
  float: right;
  margin: 0;
  padding: 0;
  margin-top: 50px;
  margin-right: 40px;
}
.showcase-box h1 span {
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 27px;
}
.showcase-box h3 {
  text-align: center;
}

.infoOneDiv img {
  width: 350px;
  box-shadow: 5px 5px 5px #9494942f;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.memberLogin-outter {
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 50px;
  font-size: 20px;
}
.memberLogin-outter h2 {
  font-family: "Logo";
  font-size: 35px;
  margin: 15px 0px;
  margin-left: -5px;
  font-weight: 300;
}
.memberLogin-outter input {
  font-size: 25px;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  margin-top: -75px;
}
/* 
.lastSpan {
  width: 70%;
  position: absolute;
  margin: 0 auto;
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 150px;
} */
.cardIcon {
  cursor: pointer;
  width: 100px;
  height: 55px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 25px;
  margin-top: 45px;
  border-radius: 10px;
  border: 1px solid #0000003b;
}
.Folio-outter {
  z-index: 100;
  position: absolute;
  display: grid;
  padding-top: 20px;
  grid-template-columns: auto;
  justify-content: space-around;
  padding-bottom: 130px;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  gap: 20px;
}
.seconfont {
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
}
.folioTitle {
  user-select: none;
  font-family: "News";
  font-weight: 300;
  font-size: 30px;
  margin: 0;
  padding: 0;
  margin-top: 6px;
}
.Folio-outter div {
  width: 380px;
  height: 500px;
}
.treasury-outter {
  margin-top: 90px;
  height: 160vh;
  background-color: #0000000c;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
}
.treasury-dashboard-head {
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 0px;
  height: 85px;
  width: 355px;
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15%;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.main-artwork-outter {
  margin-top: -140px;
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 490px;
}
.main-artwork-outter::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #ffffff00;
}
.main-artwork-two-outter {
  width: 100%;
  margin-left: 10px;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  height: 490px;
}
.main-artwork-two-outter::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #ffffff00;
}
.mainArea::-webkit-scrollbar {
  width: 0px;
}
.main-artwork-outter::-webkit-scrollbar-button {
  color: #000;
  background-color: #000;
}
.littleWidth {
  height: 415px;
  overflow: hidden;
  border-radius: 25px;
  background-color: transparent;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 25px;
  width: 5px;
}
.digitalArt-outter {
  height: 415px;
  overflow: hidden;
  border-radius: 25px;
  margin: 0 auto;
  margin-left: 20px;
  margin-right: 25px;
  width: 349px !important;
  box-shadow: 2px 2px 25px #0000001c;
  display: grid;
  gap: 10px;
  grid-template-rows: 62% 40%;
}
.digitalArt-info {
  border-top: 5px dotted #0000001e;
  margin: 0;
  padding: 0;
  margin: 0 auto;

  padding-top: 3px;
  padding-left: 15px;
  user-select: none;
}
.digitalArt-info h1 {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
}
.digitalArt-info p {
  line-height: 20px;
  margin: 0;
  padding: 0;

  width: 95%;
  border: 1px;
}
.bidBtn {
  box-shadow: 2px 2px 5px #00000031;

  height: 50px;
  z-index: 3;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 50% auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bidBtn:hover {
  color: #fff;
  background-color: #000000da;
}

.nextBtn {
  box-shadow: 2px 2px 5px #00000031;
  width: 50px;
  height: 50px;
  z-index: 3;
  border-radius: 15px;
  display: grid;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.memberCard-outter {
  border-top: 5px dotted #000;
  margin-top: -120px;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card-outter {
  margin-top: -40px;
  margin-bottom: 65px;
  border-radius: 10px;
  width: 350px;
  height: 300px;
  box-shadow: 2px 2px 5px #00000031;
}
.treasury-head {
  display: grid;
  border-bottom: 2px solid #00000018;
  align-items: center;
  height: 80px;
  margin-bottom: 25px;
}
.treasury-head-new {
  display: grid;
  border-bottom: 2px solid #00000018;
  align-items: center;
  grid-template-columns: auto auto;
  height: 80px;
  margin-bottom: 25px;
}
.treasury-head-new h1 {
  font-family: "News";
  font-weight: 300;
  font-size: 40px;
  margin: 0;
  padding: 0;
  color: #000;
  margin-top: 15px;
  text-align: center;
}
.articleTitle {
  font-size: 20px;
  padding: 5px;
  margin: 0;
  margin-top: -15px;
}
.textAreaArticle {
  height: 60px;
  margin-top: -5px;
  margin-bottom: 25px;
}
.treasury-head h1 {
  font-family: "News";
  font-weight: 300;
  font-size: 40px;
  margin: 0;
  padding: 0;
  color: #000;
  margin-top: 15px;
  text-align: center;
}
.treasury-amount {
  width: 150px;
  margin-top: 16px;
  padding: 5px 0px 5px 0px;
  border-radius: 5px;
  border: 2px solid #000;
}

.treasury-amount h2 {
  text-align: center;
  margin: 0 auto;
}
.treasury-info-main {
  height: 30px;
}

.treasury-groupchat-outter {
  height: 500px;
  background-color: #ffffff23;
  border-radius: 10px;
  margin-bottom: 170px;
  display: grid;
  grid-template-columns: auto;
}
.messages-outter {
  border: 3px solid #000000;
  background-color: #fff;
  height: 400px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.chat-input-outter {
  height: 100px;
  display: grid;
  grid-template-columns: 30% 70%;
}
.chatroom-userInfo {
  text-align: center;
  background-color: #000;
  border-radius: 10px;
  color: #fff;
  display: grid;
  width: 95px;
  height: 80px;
  margin-top: 5px;
  margin-left: 15px;
  grid-template-columns: auto;
  justify-content: center;
}
.chatroom-userInfo img {
  width: 40px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: -10px;
}
.chatroom-userInfo h3 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}
.message-textarea-outter {
  height: 90px;
  display: grid;
  grid-template-columns: 70% 20%;
  justify-content: space-evenly;
  align-items: center;
}
.message-textarea-outter div {
  overflow: hidden;
}
.message-textarea-outter textarea {
  border: 2px solid #000;
  padding: 5px;
  margin-top: 5px;
  border-radius: 10px;
  font-size: 15px;
  width: 180px !important;
  height: 65px !important;
}
.message-textarea-outter button {
  height: 50px;
  width: 50px;
  margin-left: 5px;
  margin-top: 10px;
  border: none;
  background-color: transparent;
}
.treasury-pTag {
  width: 130px;
  margin-left: 200px;
  margin-top: 230px;
  border-radius: 19px;
  background: #fff;
  z-index: 50;
  box-shadow: 3px 3px 8px #d0d0d07a, -3px -3px 8px #f0f0f07e;
  padding: 5px;
  padding-bottom: 0;
  padding-top: 0;
}
.treasury-pTag div {
  display: grid;
  grid-template-columns: 30% 70%;
  justify-content: space-around;
  align-items: center;
}
.treasury-pTag div section {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0b8626;
  box-shadow: -1px -1px 2px 1px #0b862641;
  margin-left: 20px;
}
.treasury-pTag div h2 {
  font-weight: 300;
  font-family: "Logo";
  user-select: none;
}
.div-right {
  background-color: #00000005;
  overflow: hidden;
}
.div-right h1 {
  height: 200px;
  width: 200px;
  margin: 0;
  line-height: 35px;
  margin-top: 5px;
  font-size: 35px;
  text-align: right;
  margin-right: 5px;
}
.small {
  font-size: 15px;
  font-weight: 300;
}
.div-right button {
  width: 120px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 18px;
  margin-left: 42%;
  border: none;
  border-radius: 10px;
  background-color: #000000c4;
  color: #fff;
}
.div-right button:hover {
  background-color: #000;
}
.div-left {
  background-color: #00000005;
}
.div-left h1 {
  width: 140px;
  height: 200px;
  line-height: 37px;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 40px;
}
.div-left button {
  width: 120px;
  height: 40px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  margin-left: 8px;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  background-color: #000000c4;
  color: #fff;
}
.oneImage-discover {
  width: 220px;
  margin-left: -120px;
  margin-top: -5px;
  /* margin-top: 380%; */
}
.oneImage-discover-new {
  width: 220px;
  margin-left: -120px;
  margin-top: 290%;
}
.twoImage-discover {
  width: 200px;
  margin-top: 40px;
}
.gtown-map-outter {
  border: 2px solid #000;
  height: 400px;
  display: grid;
  grid-template-columns: auto;
}
.gtown-map-outter div {
  height: 50px;
}
.left-discover {
  border: 2px solid #000;
  display: grid;
  width: 360px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  grid-template-columns: 70% 30%;
}

.originPtag {
  width: 90%;
  text-align: justify;
  margin-left: 10px;
  margin-top: -25px;
  margin-bottom: 25px;
}
.right-discover {
  border: 2px solid #000;
  display: grid;
  width: 360px;
  border-radius: 10px;
  grid-template-columns: 40% 60%;
  margin-bottom: 20px;
}
.one {
  background-image: url("./img/one.png");
  margin-top: 25px;
}
.two {
  background-image: url("./img/two.png");
}
.three {
  background-image: url("./img/three.png");
}
.four {
  background-image: url("./img/goku.jpg");
}
.five {
  background-image: url("./img/snape.jpg");
  margin-top: 25px;
  width: 59%;
  background-repeat: no-repeat;
  background-size: contain;
}
.six {
  background-image: url("./img/one\ copy.png");
}
.seven {
  background-image: url("./img/pbOne.jpg");
  margin-top: 15px;
  width: 69%;
  background-repeat: no-repeat;
  background-size: contain;
}
.eight {
  background-image: url("./img/outih.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.nine {
  background-image: url("./img/batman.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.eleven {
  background-image: url("./img/twelve.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.twelve {
  background-image: url("./img/eleven.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.ten {
  background-image: url("./img/tpb.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.nineOne {
  background-image: url("./img/911Main.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.scarface {
  background-image: url("./img/scarface.png");
  margin-top: 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.community {
  background-image: url("./img/community.png");
  margin-bottom: 5px;
}
.gal {
  background-image: url("./img/golfMain\ .jpg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 75%;
  height: 500px;
}
.gal div {
  background-color: #ffffffc0;
  width: 300px;
  margin-top: 80%;
}
.gal div h1 {
  margin: 0;
  margin-left: 10px;
  margin-top: -10px;
  font-weight: 300;
  margin-bottom: -5px;
  padding: 0;
  letter-spacing: 2.5px;
  font-family: "Logo";
}
.gal div p {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: justify;
}

.cat {
  border-left: 1px solid #000;
  background-image: url("./img/cat.png");
}

.club {
  background-image: url("./img/club.jpg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 400px;
  height: 300px;
}
.clubIn {
  background-image: url("./img/clubIn.jpg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  width: 400px;
  height: 300px;
}

.gameBtn-info {
  background-color: #fff;
  width: 400px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gameBtn-info section {
  width: 80%;
  font-family: "Logo";
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
}
.gameBtn-info section h1 {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 300;
  margin-right: 10px;
  color: #0000009c;
}
.gameBtn-info section span {
  display: flex;
  height: 25px;
  padding: 5px;
  width: 25px;
  border: 1px solid #00000038;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: -10px;
  border-radius: 50%;
  background-color: #0000000a;
}

/* These classnames for title prop values  (-- End --)*/

.newsOutter {
  width: 99%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 130px;
  overflow-x: hidden;
}

.newsPost {
  border: 2px solid #000;
  width: 90%;
  border-radius: 10px;
  padding: 5px 15px;
  margin: 0 auto;
  margin-top: 10px;
}
.newsPost h1 {
  margin: 0;
  padding: 0;
}
.newsPost h4 {
  float: right;
  margin: 0;
}
.newsPost h5 {
  margin: 0;
}
.newsPost p {
  text-align: justify;
}
.newsPost a {
  text-decoration: none;
  color: #000;
  font-style: bold;
  font-weight: 600;
}
.freestyle-outter {
  width: 99%;
  border: 1px solid #000;
  margin-top: 100px;
}

.createArticle-outter {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
  gap: 15px;
  width: 99%;
  height: 72.5vh;
  margin: 0 auto;
  margin-top: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.createArticle-outter div {
  width: 46vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #000;
  cursor: pointer;
}
.createArticle-outter div:hover {
  background-color: #000000d3;
  color: #fff;
}
.createArticle-outter div h1 {
  margin: 0;
  padding: 0;
  font-size: 30px;
}
.createArticle-outter div h1 span {
  font-family: "Logo";
  font-weight: 300;
  font-size: 52px;
}

.form-outter {
  padding-top: 15px;
  width: 80%;
  margin: 0 auto;
  margin-top: 120px;
  border-radius: 10px;
}
.form-outter-illustration {
  padding-top: 15px;
  width: 70%;
  margin: 0 auto;
  margin-top: 120px;
  border-radius: 10px;
}
.form-outter h1,
.form-outter-illustration h1 {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #00000059;
}
.form-outter h1 sub,
.form-outter-illustration h1 sub {
  margin-left: -15px;
  font-family: "Logo";
  font-weight: 100;
  font-size: 40px;
  color: #000;
}
.form-outter div {
  display: grid;
  grid-template-columns: auto;
  gap: 15px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-around;
  margin-bottom: 50px;
  padding: 10px;
}

.form-outter-illustration div {
  display: grid;
  grid-template-columns: auto;
  gap: 15px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-around;
  margin-bottom: 50px;
  padding: 10px;
}

.form-outter div input,
.form-outter div textarea,
.form-outter-illustration div input {
  font-size: 17px;
}
.form-outter div textarea {
  height: 150px;
}
.form-outter div label {
  font-size: 17px;
  margin: 0;
  letter-spacing: 3px;
}

.fileInput {
  font-size: 17px;
  margin: 0;
  letter-spacing: 3px;
}
.form-outter div button,
.form-outter-illustration div button {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  width: 100px;
  padding: 5px;
  color: #000;
  font-weight: 300;
  letter-spacing: 5px;
}

.form-outter-illustration div input {
  width: 100%;
}
.game-section {
  margin-top: 96px;
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.golf-outter {
  width: 130px;
  height: 230px;
  border: 3px solid #00000023;
  border-top-right-radius: 150px;
  border-top-left-radius: 150px;
  padding: 1px;
}
.golf-outter .mainCanvas {
  border-top-right-radius: 150px;
  border-top-left-radius: 150px;
}
.grid-two-part {
  display: grid;
  grid-template-columns: 50% 49%;
  width: 350px;
  padding: 5px 15px;
  padding-bottom: 30px;
  border-bottom: 2px solid #0000003b;
}
.window {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info h1 {
  font-family: "Logo";
  font-weight: 300;
  line-height: 33px;
  margin-bottom: 0;
  padding: 0;
  color: #000000a4;
}
.info p {
  text-align: justify;
  margin-top: 0px;
  width: 90%;
}
.info p span {
  font-size: 13px;
}
.handicapping-table-outter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.littleInfo {
  text-align: center;
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-top: -39px;
}
th,
td {
  border: 1px solid #0000004b;
  padding: 5px;
  text-align: center;
  font-size: 13px;
}
input[type="number"] {
  width: 22px;
  border: none;
  text-align: center;
}
@media screen and (max-width: 1050px) {
  .mainAreaTwo {
    display: none;
  }
  .mainArea-outter {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 600px) {
  .mainArea {
    width: 99%;
  }
  .mainAreaTwo {
    display: none;
  }
  .mainArea-outter {
    grid-template-columns: auto;
  }
  .button-outter {
    height: 45px;
    width: 45px;
  }
  .gal {
    width: 90%;
  }
  .gal div {
    margin-top: 82%;
    width: 80%;
    margin-left: 20px;
  }
  .bannerBoiler {
    height: 300px;
  }
  .createArticle-outter {
    grid-template-columns: auto;
  }
  .createArticle-outter div {
    width: 93vw;
  }
  .chatBot-outter {
    width: 370px;
  }
}
